<template>
<div>
  <timeline
    :timeline-items="dataTimeline"
    :message-when-no-items="'no items'"
    :uniqueTimeline="true"
  />
</div>
</template>

<script>
import _ from 'lodash'
import Timeline from '@/components/timeline/TimelineYear.vue'
export default {
  name: 'PolicyTimeline',
  components: {
    Timeline
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dataTimeline () {
      const results = []
      let years = []
      this.items.forEach(x => {
        if (x.policydata) {
          years.push(x.policydata.year)
        }
      })
      years = _.uniq(years)
      years.sort()
      _.each(years, year => {
        const row = { year: year, items: [] }
        _.each(this.items, item => {
          if (item.policydata) {
            if (item.policydata.year === year) {
              row.items.push({
                id: item.id,
                name: item.country,
                routeName: 'ImpactPoliciesInitiative'
              })
            }
          }
        })
        results.push(row)
      })
      return results
    }
  },
  created: function () {
    console.log(this.items)
  },
  methods: {
    /*
    [
        {
          year: 2015,
          items: [
            {
              id: 1,
              name: 'Title 1',
              routeName: 'ImpactInitiative'
            },
            {
              id: 2,
              name: 'Title 2',
              routeName: 'ImpactInitiative'
            }
          ]
        },
        {
          year: 2016,
          items: [
            {
              id: 3,
              name: 'Title 3',
              routeName: 'ImpactInitiative'
            },
            {
              id: 4,
              name: 'Title 4',
              routeName: 'ImpactInitiative'
            }
          ]
        }
      ]
      */
  }
}
</script>

<style>
</style>

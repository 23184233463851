<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
          <b-container fluid>
            <b-row class="mb-4">
              <b-col>
                <b-card>
                  <p><b-link :to="{ name: 'ImpactPolicies' }">all policies</b-link></p>
                  <h2>
                    <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'impactpolicy' }}">Edit</b-button>
                    {{item.name}}
                    <delete-sandard v-if="permission.delete" :id="item.id" :model="'impactpolicy'" />
                  </h2>
                  <div style="font-size: 1.25em">
                    {{item.summary}}
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-if="initiatives.length > 0">
              <b-col v-if="parseInt($route.params.id) === 1">
                <policy-timeline :items="initiatives" />
              </b-col>
              <b-col v-if="parseInt($route.params.id) === 2">
                <carbon-pricing-dashboard :initiatives="initiatives" />
              </b-col>
              <b-col v-if="parseInt($route.params.id) === 3">
                <ets-dashboard :initiatives="initiatives" />
              </b-col>
              <b-col v-if="parseInt($route.params.id) === 4">
                <kpmg-impact-dashboard :initiatives="initiatives" />
              </b-col>
              <b-col v-if="parseInt($route.params.id) === 5">
                <carbon-import-tariff-dashboard :initiatives="initiatives" />
              </b-col>
              <b-col v-if="parseInt($route.params.id) === 21">
                <batteries-dashboard :initiatives="initiatives" />
              </b-col>
              <b-col>
                <b-card>
                  <b-button class="mr-2" v-if="permission.addInitiative" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'impactinitiative' }}">new initiative</b-button>
                  <div v-for="initiative in initiatives" :key="'initiative'+initiative.id" class="my-3">
                    <div><router-link :to="{ name: 'ImpactPoliciesInitiative', params: { id: initiative.id }}">{{initiative.country}} - {{initiative.name}}</router-link></div>
                  </div>
                </b-card>
              </b-col>
              <b-col v-if="news.length > 0">
                <b-card>
                  <h3>News</h3>
                  <div v-for="item in news" :key="'news'+item.id" class="my-3">
                    <div><b-link :to="{ name: 'NewsItem', params: { id: item.id }}">{{item.name}}</b-link></div>
                    <div><small>{{item.countryName}} - {{item.publisher}} - {{moment(item.date).format('YYYY-MM-DD')}}</small></div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import BatteriesDashboard from '@/views/impact/policies/BatteriesDashboard.vue'
import CarbonImportTariffDashboard from '@/views/impact/policies/CarbonImportTariffDashboard.vue'
import CarbonPricingDashboard from '@/views/impact/policies/CarbonPricingDashboard.vue'
import DeleteSandard from '@/components/DeleteStandard.vue'
import EtsDashboard from '@/views/impact/policies/EtsDashboard.vue'
import KpmgImpactDashboard from '@/views/impact/policies/KpmgImpactDashboard.vue'
import PolicyTimeline from '@/components/timeline/PolicyTimeline.vue'
import ac from '@/libs/accesscontrol'

export default {
  name: 'Impactpolicy',
  components: {
    BatteriesDashboard,
    CarbonImportTariffDashboard,
    CarbonPricingDashboard,
    DeleteSandard,
    EtsDashboard,
    KpmgImpactDashboard,
    PolicyTimeline
  },
  computed: {
    initiatives: function () {
      let initiatives = this.item.initiatives
      initiatives = _.orderBy(initiatives, ['country', 'name'])
      return initiatives
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'impact policy', action: 'open impact policy', model: 'impactpolicy', model_id: this.$route.params.id })
    this.permission.addInitiative = ac.can(this.user.acgroups).createAny('impactinitiative').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('impactpolicy').granted
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('impactpolicy').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      news: [],
      permission: {
        addInitiative: false,
        delete: false,
        edit: false
      }
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.item = await this.$Amplify.API.get('cosmos', `/impact/policy/${this.$route.params.id}`)
        let news = []
        if (this.item.regtopics) {
          if (this.item.regtopics.length > 0) {
            for (const topic of this.item.regtopics) {
              if (topic.publications) {
                if (topic.publications.length > 0) {
                  news = news.concat(topic.publications)
                }
              }
            }
          }
        }
        console.log(news)
        if (news.length > 0) {
          // remove duplicates from news based on news.id
          news = _.uniqBy(news, 'id')
          this.news = _.orderBy(news, ['date'], ['desc'])
        }
        document.title = "Climate Policy Dashboard: " + this.item.name
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style scoped>

</style>

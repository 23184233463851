<template>
  <div>
    <strong>{{config.header}}</strong>
    <div :id="id" :style="`width: ${config.width}; height: ${config.height};`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['config'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const options = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: this.config.name,
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '50%'],
            data: this.config.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'config': 'reload'
  }
}
</script>

<style>
</style>

<template>
<b-card>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col class="mb-4" v-for="chart in barCharts" :key="chart.key">
        <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
      </b-col>
    </b-row>
  </b-container>
</b-card>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import dataGetters from '@/libs/dataGetters'
import theme from '@/libs/colorTheme'

import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'

echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'EtsDashboard',
  props: ['initiatives'],
  components: {
    BarStackedSimple
  },
  computed: {
    barCharts: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'directObjectMean',
          property: 'policydata',
          field: ['carbon_pricing_kpmg_impulse', 'carbon_pricing_kpmg_coverage', 'carbon_pricing_kpmg_direction', 'carbon_pricing_kpmg_horizon'],
          nameProperty: 'country',
          nameSeries: 'Composite',
          header: 'Composite impact',
          headerRecord: 'countries'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.initiatives, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>

<style>
</style>

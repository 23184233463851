<template>
<b-card>
  <b-container fluid class="bg-white">
    <b-row>
      <b-col class="mb-4" v-for="chart in barCharts" :key="chart.key">
        <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <round-simple :config="roundChart"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="countriesNoData.length > 0">
          <div><strong>Countries with no tax</strong></div>
          <div v-for="country in countriesNoData" :key="'country-no-tax-' + country">
            {{country}}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-card>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
import dataGetters from '@/libs/dataGetters'

import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
import RoundSimple from '@/components/charts/v1/roundSimple.vue'

echarts.registerTheme('kpmg', theme)

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  name: 'CarbonPricingDashboard',
  props: ['initiatives'],
  components: {
    BarStackedSimple,
    RoundSimple
  },
  computed: {
    barCharts: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'directObjectPure',
          policyId: 2,
          property: 'policydata',
          field: 'tax_rate_usd',
          nameProperty: 'country',
          nameSeries: 'Tax rate',
          header: 'Normalised tax rate USD / tCO2e',
          headerRecord: 'countries'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.initiatives, chart)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    countriesNoData: function () {
      let results = []
      this.initiatives.forEach(initiative => {
        if ('tax_rate_usd' in initiative.policydata) {
        } else {
          results.push(initiative.country)
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    roundChart: function () {
      const config = {
        data: [{ name: 'tax', value: this.barCharts[0].data.length }, { name: 'no tax', value: this.countriesNoData.length }],
        name: 'countries',
        header: 'Tax - no tax split',
        headerRecord: 'countries',
        height: '400px',
        width: '100%',
        key: getRandomKey()
      }
      return config
    }
  },
  data () {
    return {
    }
  },
  created: async function () {
  },
  methods: {
  }
}
</script>

<style>
</style>
